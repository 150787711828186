import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import img_success_copytrading from '@image/img_success_copytrading.png'
import img_success_copytrading_dark from '@image/img_success_copytrading_dark.png'
import TagSpan from '@component/Text/TagSpan'
import useDark from '@hook/useDark'

interface IProps {}

const CreateCopyDoneContainer: React.FC<IProps> = ({}) => {
    const {t} = useTranslation()
    const {isRenderDark} = useDark()
    return (
        <div>
            <Text className={'text-h2 text-gray01 dark:text-dark_gray01 text-center'}>
                {t('modal.createCopy.copyStart.title')}
            </Text>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 text-center'}>
                {t('modal.createCopy.copyStart.desc')}
            </Text>
            <WizImage
                src={isRenderDark ? img_success_copytrading_dark : img_success_copytrading}
                containerClassName={'mt-[30px] w-[450px] mx-auto sm:w-full'}
            />

            <Text className={'mt-[10px] text-ti1 text-gray01 dark:text-dark_gray01'}>
                {t('modal.createCopy.copyStart.remind.title')}
            </Text>
            <div
                className={
                    'mt-[10px] bg-gray09 dark:bg-dark_gray09 border border-gray06 dark:border-dark_gray06 rounded-[5px] px-[15px] py-[20px]'
                }>
                <ul className={'list-disc px-[18px] text-body2 text-gray02 dark:text-dark_gray02'}>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.copyStart.remind.option1')}
                        </TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.copyStart.remind.option2')}
                        </TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.copyStart.remind.option3')}
                        </TagSpan>
                    </li>
                </ul>
                <Text className={'mt-[30px] text-body2 text-gray02 dark:text-dark_gray02'}>
                    {t('modal.createCopy.copyStart.remind.paid')}
                </Text>
                <ul className={'list-disc px-[18px]'}>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.copyStart.remind.paid.option1')}
                        </TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.copyStart.remind.paid.option2')}
                        </TagSpan>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CreateCopyDoneContainer
