import React, {useCallback, useImperativeHandle, useMemo, useReducer, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import SelectLeaderKeyContainer, {
    ISelectLeaderRes,
} from '@feature/copy/components/CreateCopyModal/SelectLeaderKeyContainer/SelectLeaderKeyContainer'
import SelectCopierKeyContainer, {
    ISelectCopierRes,
} from '@feature/copy/components/CreateCopyModal/SelectCopierKeyContainer/SelectCopierKeyContainer'
import SelectCopyOptionContainer from '@feature/copy/components/CreateCopyModal/SelectCopyOptionContainer/SelectCopyOptionContainer'
import CreateCopyReviewContainer from '@feature/copy/components/CreateCopyModal/CreateCopyReviewContainer/CreateCopyReviewContainer'
import WizButton from '@component/buttons/WizButton'
import Text from '@component/Text/Text'
import StepIndicator from '@feature/copy/components/StepIndicator/StepIndicator'
import WizImage from '@component/images/WizImage'
import IconErrorMessage from '@svg/common/ic_error_message.svg'
import IconNotiError from '@svg/common/ic_noti_error.svg'
import {apiPostCreateCopier} from '@api/copy/copier/createCopier'
import useSnackbar from '@hook/useSnackbar'
import {useRouter} from 'next/router'
import {useLoginStore} from '@store/LoginStore'
import CreateCopyMyLeaderSummary from '@feature/copy/components/CreateCopyModal/CreateCopyMyLeaderSummary/CreateCopyMyLeaderSummary'
import WizContentModal from '@component/modal/WizContentModal'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import TagSpan from '@component/Text/TagSpan'
import useNavigation from '@hook/useNavigation'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import IconStartCopyTradingArrow from '@svg/copytrading/ic_start_copy_arrow.svg'
import img_dollar_blue_circle from '@image/img_dollar_blue_circle.png'
import TagText from '@component/Text/TagText'
import useQueryGetCopyTradingTerms from '@hook/query/portfolio/copyfolio/useQueryGetCopyTradingTerms'
import {createLog} from '@util/logs'
import CreateCopyDoneContainer from '@feature/copy/components/CreateCopyModal/CreateCopyDoneContainer/CreateCopyDoneContainer'
import CreateCopyTermsAndConditionsContainer from '@feature/copy/components/CreateCopyModal/CreateCopyTermsAndConditionsContainer/CreateCopyTermsAndConditionsContainer'
import IconNext from '@svg/common/ic_btn_next.svg'

enum PageSTEP {
    STEP_1,
    STEP_1_WARNING_REQUIRE_PRO,
    STEP_2,
    STEP_2_WARNING_REQUIRE_REGISTER_PRO_IP,
    STEP_3,
    STEP_4,
    STEP_4_NOTICE_BINANCE_NEED_TRUSTED_IP,
    STEP_5,
    STEP_COMPLETE,
}

interface IProps {
    leaderUserId?: string
}

export type CreateCopyModalState = {
    pageStep: PageSTEP
    selectLeader: ISelectLeaderRes
    selectCopier: ISelectCopierRes
    isEnableMirror: boolean
    isEnableStopLoss: boolean
    isErrorStopLoss: boolean
    stopLossPercentage: number
    isEnableTakeProfit: boolean
    takeProfitPercentage: number
    isErrorTakeProfit: boolean
    isLoadingCreateCopier: boolean
    isCheckConfirmCopyTradingTerms: boolean
}

const initialState: CreateCopyModalState = {
    pageStep: PageSTEP.STEP_1,
    selectLeader: undefined,
    selectCopier: undefined,
    isEnableMirror: false,
    isEnableStopLoss: true,
    isErrorStopLoss: false,
    stopLossPercentage: 0,
    isEnableTakeProfit: false,
    isErrorTakeProfit: false,
    takeProfitPercentage: 0,
    isLoadingCreateCopier: false,
    isCheckConfirmCopyTradingTerms: false,
}

const CreateCopyModal = ({leaderUserId}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const router = useRouter()

    const id = useLoginStore(state => state.id)
    const [state, dispatch] = useReducer(reducer, initialState)
    const isOpened = useRef(false)

    const {getExchange} = useQueryGetExchanges()
    const {data: me} = useQueryFetchMe(undefined, false)
    const {data: terms, refetch: refetchTerm} = useQueryGetCopyTradingTerms(isOpened.current)

    const {
        pageStep,
        selectLeader,
        selectCopier,
        isEnableMirror,
        isEnableStopLoss,
        isEnableTakeProfit,
        stopLossPercentage,
        takeProfitPercentage,
        isErrorStopLoss,
        isErrorTakeProfit,
        isLoadingCreateCopier,
        isCheckConfirmCopyTradingTerms,
    }: CreateCopyModalState = state

    function reducer(state, action) {
        switch (action.type) {
            case 'field_update':
                return {...state, [action.field]: action.value}
            case 'reset':
                return action.payload
            case 'page_back': {
                let nextPage
                switch (state.pageStep) {
                    case PageSTEP.STEP_1_WARNING_REQUIRE_PRO:
                    case PageSTEP.STEP_2:
                        nextPage = PageSTEP.STEP_1
                        break
                    case PageSTEP.STEP_2_WARNING_REQUIRE_REGISTER_PRO_IP:
                    case PageSTEP.STEP_3:
                        nextPage = PageSTEP.STEP_2
                        break
                    case PageSTEP.STEP_4:
                        nextPage = PageSTEP.STEP_3
                        break
                    case PageSTEP.STEP_4_NOTICE_BINANCE_NEED_TRUSTED_IP:
                        nextPage = PageSTEP.STEP_4
                        break
                    case PageSTEP.STEP_5:
                        nextPage = PageSTEP.STEP_4
                        break
                }

                return {
                    ...state,
                    pageStep: nextPage,
                }
            }
            case 'page_next': {
                let nextPage
                switch (state.pageStep) {
                    case PageSTEP.STEP_1:
                        nextPage = PageSTEP.STEP_2
                        break
                    case PageSTEP.STEP_2:
                        nextPage = PageSTEP.STEP_3
                        break
                    case PageSTEP.STEP_3:
                        nextPage = PageSTEP.STEP_4
                        break
                    case PageSTEP.STEP_4:
                    case PageSTEP.STEP_4_NOTICE_BINANCE_NEED_TRUSTED_IP:
                        nextPage = PageSTEP.STEP_5
                        break
                    case PageSTEP.STEP_5:
                        nextPage = PageSTEP.STEP_COMPLETE
                        break
                }

                return {
                    ...state,
                    pageStep: nextPage,
                }
            }
        }
    }

    const {showSnackbar, showErrorSnackbar} = useSnackbar()

    const reset = () => dispatch({type: 'reset', payload: initialState})

    useImperativeHandle(ref, () => ({
        show: () => {
            reset()
            modalRef.current.show()
            isOpened.current = true
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const getTitle = () => {
        if (pageStep === PageSTEP.STEP_4) return t('modal.createCopy.step4.title')
        if (pageStep === PageSTEP.STEP_5) return t('modal.createCopy.step5_term.title')
        if (pageStep === PageSTEP.STEP_COMPLETE) return ''
        else return t('modal.createCopy.step1.title')
    }

    const createCopy = useCallback(async () => {
        createLog('event', 'Create_CopyTrading_submit', {
            isEnableMirror,
            isEnableStopLoss,
            isEnableTakeProfit,
            stopLossPercentage,
            takeProfitPercentage,
        })
        dispatch({type: 'field_update', field: 'isLoadingCreateCopier', value: true})
        const {data, error, status} = await apiPostCreateCopier({
            key_id: selectCopier?.copierKey?.id,
            copy_key_id: selectLeader?.leaderKey?.id,
            name: selectLeader?.leaderKey?.copy_lead_name,
            type: 'copybot',
            terms_version: terms?.version,
            settings: {
                take_profit: isEnableTakeProfit ? takeProfitPercentage : undefined,
                stop_loss: isEnableStopLoss ? stopLossPercentage : undefined,
                mirror: isEnableMirror,
            },
        })

        if (data) {
            dispatch({type: 'page_next'})
            createLog('event', 'Create_CopyTrading_Complete')
        } else {
            createLog('error', 'Create_CopyTrading_failed', {
                isEnableMirror,
                isEnableStopLoss,
                isEnableTakeProfit,
                stopLossPercentage,
                takeProfitPercentage,
                error: error?.toString(),
            })
            showErrorSnackbar(error)
        }

        dispatch({type: 'field_update', field: 'isLoadingCreateCopier', value: false})
    }, [
        isEnableMirror,
        isEnableStopLoss,
        isEnableTakeProfit,
        stopLossPercentage,
        takeProfitPercentage,
        selectCopier,
        selectLeader,
        terms,
    ])

    const {goProLanding} = useNavigation()

    const buttonProps = useMemo(() => {
        const onClickClose = () => {
            createLog('event', 'start_copy_trading_modal_close', {step: pageStep})
            modalRef.current.close()
        }
        const onClickBack = () => {
            createLog('event', 'start_copy_trading_modal_back', {step: pageStep})
            dispatch({type: 'page_back'})
        }
        const onClickNext = () => {
            createLog('event', 'start_copy_trading_modal_next', {step: pageStep})
            dispatch({type: 'page_next'})
        }

        switch (pageStep) {
            case PageSTEP.STEP_1:
                return {
                    back: t('modal.createCopy.step1.cancel'),
                    backClick: onClickClose,
                    next: t('modal.createCopy.step1.next'),
                    disabled: selectLeader?.verified !== true,
                    nextClick: () => {
                        const isRequireProMembership =
                            selectLeader?.leaderKey?.exchange === 'bybit' ||
                            selectLeader?.leaderKey?.exchange === 'upbit'
                        if (isRequireProMembership && me?.is_pro !== true) {
                            createLog('event', 'start_copy_trading_modal_next_step_warning_require_pro')
                            dispatch({
                                type: 'field_update',
                                field: 'pageStep',
                                value: PageSTEP.STEP_1_WARNING_REQUIRE_PRO,
                            })
                        } else {
                            onClickNext()
                        }
                    },
                }
            case PageSTEP.STEP_1_WARNING_REQUIRE_PRO:
                return {
                    back: t('modal.createCopy.step1_warning_need_pro.cancel'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step1_warning_need_pro.next'),
                    disabled: selectLeader?.verified !== true,
                    nextClick: () => router?.push(`/account/pro/payment`),
                }
            case PageSTEP.STEP_2:
                return {
                    back: t('modal.createCopy.step2.back'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step2.next'),
                    disabled: selectCopier?.verified !== true,
                    nextClick: () => {
                        const copierKey = selectCopier?.copierKey
                        const isRequireProMembership =
                            selectLeader?.leaderKey?.exchange === 'upbit' ||
                            selectLeader?.leaderKey?.exchange === 'bybit'

                        if (isRequireProMembership && copierKey?.pro_ip_status !== 'set') {
                            createLog('error', 'start_copy_trading_modal_next_step_warning_require_pro_ip', {
                                id: copierKey?.id,
                                exchange: selectLeader?.leaderKey?.exchange,
                                ip_status: copierKey?.pro_ip_status,
                            })
                            dispatch({
                                type: 'field_update',
                                field: 'pageStep',
                                value: PageSTEP.STEP_2_WARNING_REQUIRE_REGISTER_PRO_IP,
                            })
                        } else {
                            onClickNext()
                        }
                    },
                }
            case PageSTEP.STEP_2_WARNING_REQUIRE_REGISTER_PRO_IP:
                return {
                    back: t('modal.createCopy.step2_warning_require_pro_ip.cancel'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step2_warning_require_pro_ip.updateNow'),
                    nextClick: () => router.push(`/account/api`),
                }
            case PageSTEP.STEP_3:
                return {
                    back: t('modal.createCopy.step3.back'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step3.next'),
                    nextClick: () => {
                        const isCheckErrorStopLoss = isEnableStopLoss && stopLossPercentage < 3
                        const isCheckErrorTakeProfit = isEnableTakeProfit && takeProfitPercentage < 3

                        if (!isCheckErrorStopLoss && !isCheckErrorTakeProfit) {
                            createLog('event', 'start_copy_trading_modal_next', {
                                step: pageStep,
                                isEnableMirror,
                                isEnableStopLoss,
                                isEnableTakeProfit,
                                stopLossPercentage,
                                takeProfitPercentage,
                            })
                            dispatch({type: 'page_next'})
                        } else {
                            createLog('error', 'start_copy_trading_modal_next_error', {
                                step: pageStep,
                                isCheckErrorStopLoss,
                                isCheckErrorTakeProfit,
                            })
                            dispatch({type: 'field_update', field: 'isErrorStopLoss', value: isCheckErrorStopLoss})
                            dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: isCheckErrorTakeProfit})
                        }
                    },
                }
            case PageSTEP.STEP_4:
                return {
                    back: t('modal.createCopy.step4.back'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step4.next'),
                    nextClick: () => {
                        if (
                            selectLeader?.leaderKey?.exchange === 'binance' &&
                            selectCopier?.copierKey?.ip_status !== 'set'
                        ) {
                            createLog('event', 'start_copy_trading_modal_next_step_notice_binance_need_trusted_ip', {
                                id: selectCopier?.copierKey?.id,
                                exchange: selectLeader?.leaderKey?.exchange,
                                ip_status: selectCopier?.copierKey?.ip_status,
                            })
                            dispatch({
                                type: 'field_update',
                                field: 'pageStep',
                                value: PageSTEP.STEP_4_NOTICE_BINANCE_NEED_TRUSTED_IP,
                            })
                        } else {
                            onClickNext()
                        }
                    },
                }
            case PageSTEP.STEP_4_NOTICE_BINANCE_NEED_TRUSTED_IP:
                return {
                    back: t('modal.createCopy.step4_require_pro_ip.back'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step4_require_pro_ip.startCopying'),
                    nextClick: onClickNext,
                }

            case PageSTEP.STEP_5:
                return {
                    back: t('modal.createCopy.step5_term.back'),
                    backClick: onClickBack,
                    next: t('modal.createCopy.step5_term.startCopy'),
                    disabled: isCheckConfirmCopyTradingTerms !== true,
                    nextClick: createCopy,
                }

            case PageSTEP.STEP_COMPLETE:
                return {
                    next: t('modal.createCopy.copyStart.understand'),
                    nextClick: () => {
                        createLog('event', 'start_copy_trading_modal_complete')
                        modalRef.current.close()
                        router.push(`/user/${id}/copyfolio`)
                    },
                }
        }
    }, [
        pageStep,
        selectLeader,
        selectCopier,
        stopLossPercentage,
        takeProfitPercentage,
        isEnableStopLoss,
        isEnableTakeProfit,
        isEnableMirror,
        isCheckConfirmCopyTradingTerms,
        id,
        me?.is_pro,
        router,
        t,
        createCopy,
    ])

    return (
        <WizContentModal
            title={getTitle()}
            ref={modalRef}
            titleClassName={'pb-[30px]'}
            heightClassName={`relative rotate-0 ${
                pageStep === PageSTEP.STEP_1 || pageStep === PageSTEP.STEP_2 ? 'sm:max-h-[100dvh]' : ''
            }`}
            size={'800'}>
            <div
                className={`${
                    selectLeader?.errorMessage || pageStep === PageSTEP.STEP_3 || pageStep === PageSTEP.STEP_4
                        ? 'mb-[116px] sm:mb-[126px]'
                        : 'mb-[85px] '
                }`}>
                {pageStep === PageSTEP.STEP_1 && (
                    <div
                        className={`${
                            selectLeader?.errorMessage ? 'max-h-[calc(100dvh-216px)]' : 'max-h-[calc(100dvh-185px)]'
                        } overflow-auto pr-[5px] not_bg_scrollbar `}>
                        <div className={''}>
                            <div
                                className={
                                    'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                                }>
                                <Text className={'text-body1 sm:text-left sm:w-full text-gray01 dark:text-dark_gray01'}>
                                    {t('modal.createCopy.step1.desc')}
                                </Text>
                                <StepIndicator
                                    totalStep={5}
                                    currentStep={0}
                                    className={'sm:w-full sm:flex sm:justify-end'}
                                />
                            </div>
                            <div
                                className={`py-[10px] px-[20px] mt-[20px] rounded-[3px] sm:rounded-[0px] bg-blue_tint02 dark:bg-dark_blue_tint02 flex items-center justify-center gap-x-[5px]`}>
                                <WizImage src={img_dollar_blue_circle} width={24} containerClassName={'flex-none'} />
                                <TagText className={'text-body2 text-blue_shade dark:text-dark_blue_shade'}>
                                    {t('modal.createCopy.step1.paid.banner.text')}
                                </TagText>
                            </div>

                            <SelectLeaderKeyContainer
                                leaderUserID={leaderUserId}
                                selectedID={selectLeader?.leaderKey?.id}
                                onChanged={item => dispatch({type: 'field_update', field: 'selectLeader', value: item})}
                            />
                        </div>
                    </div>
                )}
                {pageStep === PageSTEP.STEP_1_WARNING_REQUIRE_PRO && (
                    <div className={'mt-[10px] px-[20px]'}>
                        <div className={'flex justify-center'}>
                            <IconNotiError className={'w-[70px] mt-[40px] fill-red dark:fill-dark_red'} />
                        </div>
                        <Text className={'font-bold text-center mt-[30px] text-gray01 dark:text-dark_gray01'}>
                            {t('modal.createCopy.step1_warning_need_pro.title')}
                        </Text>

                        <ul
                            className={
                                'list-disc list-inside sm:list-outside sm:pl-[20px] text-gray02 dark:text-dark_gray02 mt-[10px]'
                            }>
                            <li>
                                <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step1_warning_need_pro.desc')}
                                </TagSpan>
                            </li>
                        </ul>

                        <Text className={'text-body2 font-bold text-gray02 dark:text-dark_gray02 mt-[30px]'}>
                            {t('modal.createCopy.step1_warning_need_pro.whyGoPro.title')}
                        </Text>
                        <ul
                            className={
                                'list-disc list-inside sm:list-outside sm:pl-[20px] text-gray02 dark:text-dark_gray02'
                            }>
                            <li>
                                <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step1_warning_need_pro.whyGoPro.option1')}
                                </TagSpan>
                            </li>
                            <li>
                                <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step1_warning_need_pro.whyGoPro.option2')}
                                </TagSpan>
                            </li>
                            <li>
                                <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step1_warning_need_pro.whyGoPro.option3')}
                                </TagSpan>
                            </li>
                            <li>
                                <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step1_warning_need_pro.whyGoPro.option4')}
                                </TagSpan>
                            </li>
                        </ul>

                        <div
                            className={'mt-[30px] flex justify-center'}
                            onClick={() => {
                                createLog('event', 'start_copy_trading_step1_click_learn_more_pro')
                                goProLanding()
                            }}>
                            <Text className={'text-body1 underline cursor-pointer text-gray01 dark:text-dark_gray01'}>
                                {t('modal.createCopy.step1_warning_need_pro.whatIsPro')}
                            </Text>
                        </div>
                    </div>
                )}
                {pageStep === PageSTEP.STEP_2 && (
                    <div className={'mt-[10px] max-h-[calc(100dvh-195px)] overflow-auto pr-[5px] not_bg_scrollbar '}>
                        <div
                            className={
                                'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                            }>
                            <Text className={'text-body1 sm:text-left sm:w-full text-gray01 dark:text-dark_gray01'}>
                                {t('modal.createCopy.step2.desc')}
                            </Text>
                            <StepIndicator
                                totalStep={5}
                                currentStep={1}
                                className={'sm:w-full sm:flex sm:justify-end'}
                            />
                        </div>

                        <Text className={'text-ti2 mt-[20px] text-gray01 dark:text-dark_gray01'}>
                            {t('modal.createCopy.step2.myCopyLeader.title')}
                        </Text>
                        <CreateCopyMyLeaderSummary className={'mt-[10px]'} leaderId={selectLeader?.leaderKey?.id} />
                        <div className={'flex items-center gap-x-[10px] mt-[10px] justify-center'}>
                            <IconStartCopyTradingArrow className={'w-[25px] fill-blue dark:fill-dark_blue'} />
                            <Text className={'text-blue dark:text-dark_blue text-body3'}>
                                {t('modal.createCopy.step2.keys.readyText', {
                                    leaderName: selectLeader?.leaderKey?.copy_lead_name,
                                })}
                            </Text>
                            <IconStartCopyTradingArrow className={'w-[25px] fill-blue dark:fill-dark_blue'} />
                        </div>
                        <SelectCopierKeyContainer
                            className={'mt-[30px]'}
                            leader={selectLeader?.leaderKey}
                            selectedID={selectCopier?.copierKey?.id}
                            onChanged={item => {
                                createLog('event', 'start_copy_trading_step2_select_copier_key', {
                                    copier_id: item?.copierKey?.id,
                                })
                                dispatch({type: 'field_update', field: 'selectCopier', value: item})
                            }}
                        />
                    </div>
                )}
                {pageStep === PageSTEP.STEP_2_WARNING_REQUIRE_REGISTER_PRO_IP && (
                    <div className={'mt-[10px] px-[20px]'}>
                        <div className={'flex justify-center'}>
                            <IconNotiError className={'w-[70px] mt-[40px] fill-red dark:fill-dark_red'} />
                        </div>
                        <Text className={'font-bold text-center mt-[30px] text-gray01 dark:text-dark_gray01'}>
                            {t('modal.createCopy.step2_warning_require_pro_ip.title')}
                        </Text>

                        <ul
                            className={
                                'list-disc list-inside sm:list-outside sm:pl-[20px] text-gray02 dark:text-dark_gray02 mt-[10px]'
                            }>
                            <li>
                                <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step2_warning_require_pro_ip.desc', {
                                        exchange: getExchange(selectLeader?.leaderKey?.exchange)?.display_name,
                                    })}
                                </TagSpan>
                            </li>
                        </ul>
                    </div>
                )}
                {pageStep === PageSTEP.STEP_3 && (
                    <div className={'mt-[10px]'}>
                        <div
                            className={
                                'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                            }>
                            <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                                {t('modal.createCopy.step3.desc')}
                            </Text>
                            <StepIndicator
                                totalStep={5}
                                currentStep={2}
                                className={'sm:w-full sm:flex sm:justify-end'}
                            />
                        </div>

                        <SelectCopyOptionContainer
                            className={'mt-[10px] sm:mt-[20px]'}
                            exchange={selectLeader?.leaderKey?.exchange}
                            userBalance={selectCopier?.copierKey?.current_balance}
                            enableMirrorMode={isEnableMirror}
                            enableStopLoss={isEnableStopLoss}
                            enableTakeProfit={isEnableTakeProfit}
                            onChangeMirrorMode={isEnable =>
                                dispatch({type: 'field_update', field: 'isEnableMirror', value: isEnable})
                            }
                            onChangeStopLoss={isEnable => {
                                dispatch({type: 'field_update', field: 'isErrorStopLoss', value: false})
                                dispatch({type: 'field_update', field: 'isEnableStopLoss', value: isEnable})
                            }}
                            onChangeTakeProfit={isEnable => {
                                dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: false})
                                dispatch({type: 'field_update', field: 'isEnableTakeProfit', value: isEnable})
                            }}
                            isErrorStopLoss={isErrorStopLoss}
                            isErrorTakeProfit={isErrorTakeProfit}
                            stopLossPercentage={stopLossPercentage}
                            takeProfitPercentage={takeProfitPercentage}
                            onChangeStopLossPercentage={number => {
                                dispatch({type: 'field_update', field: 'isErrorStopLoss', value: false})
                                dispatch({type: 'field_update', field: 'stopLossPercentage', value: number})
                            }}
                            onChangeTakeProfitPercentage={number => {
                                dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: false})
                                dispatch({type: 'field_update', field: 'takeProfitPercentage', value: number})
                            }}
                        />
                    </div>
                )}
                {pageStep === PageSTEP.STEP_4 && (
                    <div className={'mt-[10px] mb-[-20px] sm:mb-[-40px]'}>
                        <div
                            className={
                                'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                            }>
                            <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                                {t('modal.createCopy.step4.desc')}
                            </Text>
                            <StepIndicator
                                totalStep={5}
                                currentStep={3}
                                className={'sm:w-full sm:flex sm:justify-end'}
                            />
                        </div>

                        <CreateCopyReviewContainer
                            {...state}
                            userBalance={selectCopier?.copierKey?.current_balance}
                            className={'mt-[10px] sm:mt-[20px]'}
                        />
                        <div
                            className={'flex items-center justify-end mt-[15px] cursor-pointer'}
                            onClick={() => {
                                createLog('event', 'start_copy_trading_step4_click_change_setting', {step: pageStep})
                                dispatch({type: 'page_back'})
                            }}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.createCopy.step4.changeSetting')}
                            </Text>
                            <IconNext className={'w-[12px] fill-gray02 dark:fill-dark_gray02'} />
                        </div>
                    </div>
                )}
                {pageStep === PageSTEP.STEP_4_NOTICE_BINANCE_NEED_TRUSTED_IP && (
                    <div className={'mt-[30px]'}>
                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.createCopy.step4_require_pro_ip.title')}
                        </Text>
                        <div
                            className={
                                'mt-[10px] px-[20px] py-[15px] bg-gray09 dark:bg-bg_dark_white02 border border-gray06 dark:border-dark_gray06 rounded-[5px]'
                            }>
                            <ul className={'list-disc list-outside pl-[20px] text-gray02 dark:text-dark_gray02'}>
                                <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    <TagSpan>{t('modal.createCopy.step4_require_pro_ip.option1')}</TagSpan>
                                </li>
                                <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    <TagSpan>{t('modal.createCopy.step4_require_pro_ip.option2')}</TagSpan>
                                </li>
                                <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    <TagSpan>{t('modal.createCopy.step4_require_pro_ip.option3')}</TagSpan>
                                </li>
                                <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    <TagSpan>{t('modal.createCopy.step4_require_pro_ip.option4')}</TagSpan>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                {pageStep === PageSTEP.STEP_5 && (
                    <div>
                        <div
                            className={
                                'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                            }>
                            <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                                {t('modal.createCopy.step5_term.desc')}
                            </Text>
                            <StepIndicator
                                totalStep={5}
                                currentStep={4}
                                className={'sm:w-full sm:flex sm:justify-end'}
                            />
                        </div>
                        <CreateCopyTermsAndConditionsContainer
                            isCheckConfirmCopyTradingTerms={isCheckConfirmCopyTradingTerms}
                            onChangeCheckConfirmCopyTradingTerms={isEnable => {
                                createLog('event', 'start_copy_trading_step5_check_terms_conditions', {
                                    step: pageStep,
                                    copier_id: selectCopier?.copierKey?.id,
                                    isEnable,
                                })
                                dispatch({
                                    type: 'field_update',
                                    field: 'isCheckConfirmCopyTradingTerms',
                                    value: isEnable,
                                })
                            }}
                            terms={terms?.terms}
                        />
                    </div>
                )}
                {pageStep === PageSTEP.STEP_COMPLETE && (
                    <div>
                        <CreateCopyDoneContainer {...state} />
                    </div>
                )}
            </div>
            <div
                className={
                    'flex flex-col justify-center pt-[30px] fixed bottom-0 left-0 w-full bg-white dark:bg-bg_dark_popup pb-[30px] px-[40px] sm:px-[20px]'
                }>
                {(pageStep === PageSTEP.STEP_1 || pageStep === PageSTEP.STEP_2) && (
                    <div
                        className={`absolute top-[-19px] left-[30px] h-[20px] w-[calc(100%-70px)] sm:left-[20px] sm:w-[calc(100%-50px)] bg-gradient-to-t from-white dark:from-bg_dark_popup to-transparent`}></div>
                )}
                {pageStep === PageSTEP.STEP_1 && selectLeader?.errorMessage && (
                    <div className={'flex items-center space-x-[5px] justify-center mb-[10px]'}>
                        <IconErrorMessage className={'w-[16px] fill-red dark:fill-dark_red'} />
                        <Text className={'text-body2 text-red_shade dark:text-dark_red_shade'}>
                            {selectLeader?.errorMessage}
                        </Text>
                    </div>
                )}
                {pageStep === PageSTEP.STEP_3 && (
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center mb-[10px]'}>
                        {t('modal.createCopy.step3.bottomDesc')}
                    </Text>
                )}
                <div className={'flex space-x-[10px] sm:grow max-w-[500px] mx-auto sm:w-[100%]'}>
                    {pageStep !== PageSTEP.STEP_COMPLETE && (
                        <WizButton
                            className={'w-[100px] sm:w-[auto] sm:grow-[150]'}
                            text={buttonProps?.back}
                            buttonType={'border'}
                            onClick={buttonProps?.backClick}
                        />
                    )}
                    <WizButton
                        className={`w-[250px] ${
                            pageStep === PageSTEP.STEP_COMPLETE && 'w-[500px]'
                        } sm:w-[auto] sm:grow-[340]`}
                        text={buttonProps?.next}
                        buttonType={'secondary'}
                        disabled={buttonProps?.disabled}
                        onClick={buttonProps?.nextClick}
                    />
                </div>
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(CreateCopyModal)
